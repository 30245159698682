// COLORS
$color-dark: #000000;
$color-dark-2: #000024;
$color-dark-3: #03002e;
$color-dark-4: #3c3c3b;
$color-dark-5: #00002433;
$color-dark-6: #333333;
$color-grey: #777777;
$color-grey-2: #a3a3a3;
$color-grey-3: #a4a4a4;
$color-grey-4: #c4c4c4;
$color-grey-5: #e3e3e3;
$color-grey-6: #e1e1e1;
$color-grey-7: #9b9b9b;
$color-grey-8: #ececec;
$color-grey-9: #cccccc;
$color-grey-10: #999999;
$color-grey-11: #aaaaaa;
$color-grey-12: #d7d7d7;
$color-grey-13: #7f7f7f;
$color-grey-14: #f8f8f8;
$color-grey-15: #555555;
$color-grey-16: #78788a;
$color-grey-17: #dee2e6;
$color-grey-18: #e9ecef;
$color-grey-19: #ced4da;
$color-grey-20: #878787;
$color-grey-21: #cfd4da;
$color-blue: #0a12bf;
$color-blue-2: #03002e;
$color-blue-3: #0d6efd;
$color-blue-4: #0a58ca;
$color-red: #ff0000;
$color-red-1: #d9001b;
$color-white: #ffffff;
$color-white-2: #f9f9f9;
$color-white-3: #f0f0f0;
$color-white-4: #dddddd;
$color-white-5: #eeeeee;
$color-white-6: #e2e2e8;
$color-white-7: #f2f2f2;
$color-yellow: #ff7c1e;
$color-yellow-2: #e05e00;
$color-purple: #7042d4;
$color-brown: #747481;
$color-green: #57d401;

// FONT
$font-family: "Ubuntu";
$font-family-bold: "Ubuntu Bold";

// BREAK_POINT
$break-point-mobile: 600px;
$break-point-mobile-2: 767px;
$break-point-tablet: 900px;
$break-point-tablet-1: 1236px;
$break-point-tablet-2: 976px;
$break-point-desktop: 1259px;
$break-point-desktop-1: 965px;
