@import "../abstracts/variables";
@import "../abstracts/mixins";

body {
  font-size: 16px;
  font-family: Ubuntu, sans-serif;
  color: $color-dark-2;
}

.intro-text {
  font-size: 18px;
  line-height: 21px;
  font-family: $font-family;

  @include mobile_width {
    font-size: 16px;
  }

  &--bold {
    font-size: 18px;
    line-height: 21px;
    font-family: $font-family-bold;

    @include mobile_width {
      font-size: 16px;
    }
  }
}
