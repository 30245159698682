@import "../abstracts/variables";
@import "../abstracts/mixins";

.flex-spacer {
  flex: 1;
}

.table-responsive {
  margin-bottom: 20px;

  table {
    color: $color-dark;
    margin-bottom: 0;
  }

  @include scrollbar_square;
}

.button {
  width: 144px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  border: none;

  @include tablet_width {
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  &:disabled {
    cursor: default !important;
    background-color: $color-grey-6 !important;
    color: $color-grey-7 !important;
  }

  &--blue {
    background-color: $color-blue;
    color: $color-white;

    &:hover {
      background-color: $color-blue-2;
    }
  }

  &--grey {
    background-color: $color-grey-13;
    color: $color-white;

    &:hover {
      background-color: $color-blue-2;
    }
  }

  &--brown {
    background-color: $color-brown;
    color: $color-white;

    &:hover {
      background-color: $color-blue-2;
    }
  }

  &--loading {
    background-color: $color-blue;
    color: $color-white;
    cursor: default !important;
  }
}

.button-link {
  border: none;
  font-size: 16px;
  text-decoration: underline;
  background-color: $color-white;
  margin-right: 20px;
  font-family: $font-family;

  &:hover {
    background-color: $color-white;
  }

  &--grey {
    color: $color-grey;
  }

  &--blue {
    color: $color-blue;
  }
}

.processing-page {
  background-color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 314px;
  font-size: 24px;
  color: $color-grey;

  @include mobile_width {
    font-size: 20px;
    width: 100%;
    word-wrap: break-word;
  }
}

.checkbox {
  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 44px;
    padding: 0 20px;
    cursor: pointer;

    &:hover {
      background-color: $color-white-3;
    }
  }

  &__label {
    max-width: 90%;

    &:hover {
      cursor: pointer;
    }
  }

  &__checkmark {
    margin-left: auto;
    width: 16px;
    height: 16px;
    background-color: $color-white;
    border: 1px solid $color-grey;
    display: flex;
    align-items: center;
    justify-content: center;

    &--checked {
      background-color: $color-dark-2;
      border: none;
    }

    img {
      width: 8px;
      height: 8px;
    }
  }
}

.font-bold {
  font-family: $font-family-bold;
}

.font-error {
  color: $color-red;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
}

.total {
  float: right;
  padding-bottom: 8px;
}

.not-allowed {
  cursor: not-allowed !important;
}

.none-stroke {
  stroke: none;
}

.text-link {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: $color-blue-3;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  .custom-control-input {
    height: 23px;
    width: 23px;
    min-width: 23px;
    margin: 0;
    padding: 0;
    opacity: 1;
    appearance: none;
    border: 1px solid $color-dark;
    border-radius: 3px;
    background: #fff;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
  }

  .custom-control-input:disabled {
    opacity: 1;
    background-color: $color-white-3;
    cursor: initial;
  }

  .custom-control-input:checked {
    border: 1px solid $color-dark;
  }

  .custom-control-input:checked:before,
  .custom-control-input:checked:after {
    content: "";
    position: absolute;
    height: 2px;
    background: $color-dark;
  }

  .custom-control-input:checked:before {
    width: 6px;
    top: 12px;
    left: 4px;
    transform: rotate(44deg);
  }

  .custom-control-input:checked:after {
    width: 12px;
    top: 10px;
    left: 6px;
    transform: rotate(-55deg);
  }

  .custom-control-input:focus {
    outline: none;
  }
}

.custom-checkbox-invalid {
  .custom-control-input {
    border: 1px solid $color-red !important;
  }

  .custom-control-label {
    color: $color-red;
  }
}

.form-control:disabled {
  opacity: 1;
  background-color: $color-white-3;
  cursor: initial;
  color: $color-grey-20;
}

.form-control-checkbox > input:disabled {
  opacity: 1;
  background-color: $color-white-3;
  border: 1px solid $color-grey-19;
  cursor: initial;
}

input:disabled {
  opacity: 1;
  background-color: $color-white-3;
  border: 1px solid $color-grey-19;
  cursor: initial;
}

.custom-checkbox {
  input:disabled {
    border: 1px solid $color-grey-19 !important;
    color: $color-grey-20;
  }
}

.cursor-default {
  cursor: default;
}

.color-grey {
  color: $color-grey;
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  .form-control:disabled {
    color: $color-dark-6;
  }
  .custom-checkbox {
    input:disabled {
      color: $color-dark-6;
    }
  }
}

.color-blue {
  color: $color-blue;
}

.color-green {
  color: $color-green;
}

.bg-color {
  &__Completed {
    background-color: $color-green;
  }
  &__Generating {
    background-color: $color-yellow;
  }
  &__Failed {
    background-color: $color-grey;
  }

  &__Completed,
  &__Generating,
  &__Failed {
    display: flex;
    width: 120px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    color: $color-white;
    height: 30px;
  }
}

.btn-hidden {
  cursor: default;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.cursor-pointer {
  cursor: pointer;
}

.ant-table {
  overflow-x: auto;
}