@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.authen {
  width: 100%;
  min-height: 100vh;
  background: url("../../../assets/images/login-bg-desktop.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  padding-top: 160px;

  &__box {
    width: 456px;
    max-height: 386px;
    background-color: $color-white;
    padding: 32px 32px;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    @include mobile_width {
      width: 300px;
      max-height: 440px;
    }
  }

  &__heading {
    font-size: 28px;
    font-weight: 400;
    line-height: 32px;
    color: $color-dark;
    @include mobile_width {
      font-size: 26px;
    }
  }

  &__line {
    width: 162px;
    margin: 20px 0;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $color-grey;
    opacity: 0.7;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    width: 200px;
    background-color: $color-blue;
    color: $color-white;
    text-decoration: none;
    border: none;
    border-radius: 0;

    &:hover {
      text-decoration: none;
      color: $color-white;
      background-color: $color-blue-2;
      cursor: pointer;
    }
  }

  &__logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 34px;
    width: 100%;
    max-width: 350px;

    .logo {
      &--abp {
        width: 144px;
        height: 90px;
      }

      &--live-stock {
        width: 165px;
        height: 47px;
      }
    }
  }

  &__error {
    font-size: 18px;
    line-height: 21px;
    margin-top: 7px;
    font-weight: 400;
    color: $color-dark;
    justify-content: space-between;
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
