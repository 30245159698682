@import "../abstracts/variables";
@import "../abstracts/mixins";

#ccc-content {
  font-family: $font-family;
}

#ccc-notify {
  display: block !important;
  height: 160px !important;

  @include tablet_width {
    display: flex !important;
    height: auto !important;
  }
}

.ccc-notify-buttons {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  top: -40px;

  @include tablet_width {
    top: 0px;
  }
}

#ccc .ccc-notify-buttons .ccc-notify-button {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  border: none !important;
  border-radius: 0 !important;
  text-decoration: none;
  padding: 8px 20px !important;

  &:hover {
    background-color: $color-blue-2 !important;
  }

  span {
    color: $color-white !important;
    text-decoration: none;
  }
}

#ccc .ccc-notify-buttons .ccc-notify-link {
  background-color: $color-grey-15 !important;
}

#ccc .ccc-notify-buttons .ccc-accept-button {
  background-color: $color-blue !important;
}

#ccc-icon {
  display: none;
}

#ccc-recommended-settings {
  background-color: $color-blue !important;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  border: none !important;
  border-radius: 0 !important;
  text-decoration: none;
  padding: 8px 20px !important;

  &:hover {
    background-color: $color-blue-2 !important;
  }

  span {
    color: $color-white !important;
    text-decoration: none;
  }
}

#ccc .ccc-module--popup #ccc-content {
  max-width: 672px;
  border-radius: 0;
}
