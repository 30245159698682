*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  padding: 0 !important;
}

img {
  width: 100%;
}
