@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.not-found {
  width: 100%;
  min-height: 100vh;
  background: url("../../../assets/images/login-bg-desktop.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  padding-top: 110px;

  &__box {
    width: 800px;
    height: 570px;
    background-color: $color-white;
    padding: 30px 140px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include tablet_width {
      width: 600px;
      padding: 20px 50px;
    }

    @include mobile_width {
      width: 300px;
      padding: 10px 30px;
    }
  }

  &__404 {
    font-size: 96px;
    font-family: $font-family-bold;
    margin-bottom: 0;
  }

  &__heading {
    font-size: 24px;
    font-family: $font-family-bold;
    color: $color-grey-2;
    position: relative;
    top: -15px;
  }

  &__line {
    width: 226px;
    margin: -10px 0 20px 0;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $color-grey-2;
    opacity: 0.7;
  }

  &__logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 350px;

    .logo {
      &--abp {
        width: 144px;
        height: 90px;

        @include mobile_width {
          width: 100px;
          height: 80px;
        }
      }

      &--live-stock {
        width: 144px;
        height: 90px;

        @include mobile_width {
          width: 100px;
          height: 80px;
        }
      }
    }
  }

  &__error {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 0;
    margin-top: 15px;
    text-align: left;
    font-weight: 400;
    color: $color-dark;
  }

  &__temporary_error {
    color: $color-red !important;
  }

  &__button {
    margin-top: 55px;
    width: 200px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: $color-blue;
    color: $color-white;
    text-decoration: none;

    &:hover {
      color: $color-white;
      background-color: $color-blue-2;
    }
  }
}