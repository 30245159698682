@import "./node_modules/react-toastify/scss/main";

.Toastify__toast::before{
    content: '';
    width: 5px;
    background-color: var(--toastify-color-error);
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
}
.Toastify__toast{
    border-radius: 0  !important;
}

.Toastify__close-button {
    align-self: center !important;
}